import { DELETE, FETCH } from "./action-types";
import { SET_ENTITIES, SET_IS_LOADING } from "./mutation-types";

export default apiModule => ({
  async [FETCH]({ commit }, { id }) {
    try {
      commit(SET_IS_LOADING, true);
      const { entities, result } = await apiModule.fetch(id);
      commit(SET_ENTITIES, { entities, id: result });
      commit(SET_IS_LOADING, false);
      return result;
    } catch (e) {
      console.error("Error with DETAIL module fetch action:", e);
    }
  },
  async [DELETE](context, { id }) {
    const { result } = await apiModule.remove(id);
    return result;
  }
});

import geocodeApi from "@/api/geocode";
import submissionApi from "@/api/submission";
import leadApi from "@/api/lead";
import offerApi from "@/api/offer";
import offerFieldApi from "@/api/offer-field";
import leadFieldApi from "@/api/lead-field";
import actions from "@/store/templates/detail/actions";
import { SET_ENTITIES } from "@/store/templates/detail/mutation-types";
import {
  CLOSE_DIALOG,
  SET_GEOCODE_OPTIONS,
  SET_IS_ADDRESS_EDIT_PROCESSING,
  SET_IS_ADDRESS_RESET_PROCESSING,
  SET_IS_GEOCODING,
  SET_IS_PROCESSING,
  SET_IS_SUBJECT_PROCESSING,
  SET_OFFER_PROCESSING,
  SET_VALIDATION_PROCESSING_ID
} from "@/store/modules/submission/mutation-types";

export default {
  ...actions(submissionApi),
  async fetchGeocode({ commit }, { q }) {
    commit(SET_IS_GEOCODING, true);
    commit(SET_GEOCODE_OPTIONS, await geocodeApi.fetch(q));
  },
  async geocode({ state, commit, dispatch }, geocodeAddress) {
    commit(SET_IS_GEOCODING, true);
    const { entities, result } = await submissionApi.geocode(
      state.id,
      geocodeAddress
    );

    commit(SET_IS_GEOCODING, false);
    commit(SET_ENTITIES, { entities, id: result });
    dispatch(
      "snackbar/addInfo",
      { text: "Geocoded submission." },
      { root: true }
    );
    return result;
  },
  /**
   * Rejects the submission. Optionally soft-rejects if the isSoft flag is provided and true.
   *
   * @param {Object} state Vuex state object
   * @param {Function} commit Vuex commit function
   * @param {Function} dispatch Vuex dispatch function
   * @param {number} rejectReasonId id of the reason we're rejecting
   * @param {string} [rejectComment=undefined] comment from buldit.
   * @param {boolean} [isSoft=undefined] boolean indicating whether to soft reject.
   * @returns {Promise<*>}
   */
  async reject(
    { state, commit, dispatch },
    { isSoft = undefined, ...rejectForm }
  ) {
    const { entities, result } = await submissionApi.reject(state.id, {
      isSoft,
      ...rejectForm
    });
    commit(SET_ENTITIES, { entities, id: result });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: `${isSoft ? "Soft-rejected" : "Rejected"} submission.` },
      { root: true }
    );
    return result;
  },
  async cancelReject({ state, commit, dispatch }) {
    const { entities, result } = await submissionApi.cancelReject(state.id);
    commit(SET_ENTITIES, { entities, id: result });
    dispatch(
      "snackbar/addInfo",
      { text: "Rejection cancelled. However, sent emails REMAIN sent." },
      { root: true }
    );
    return result;
  },
  async ignoreValidation({ commit, state, dispatch }, { id }) {
    commit(SET_VALIDATION_PROCESSING_ID, id);
    const { entities, result } = await submissionApi.validationRule.ignore(
      state.id,
      id
    );
    commit(SET_ENTITIES, { entities, id: result });
    commit(SET_VALIDATION_PROCESSING_ID, null);
    dispatch(
      "snackbar/addInfo",
      { text: "Ignored validation." },
      { root: true }
    );
    return result;
  },
  async unignoreValidation({ commit, state, dispatch }, { id }) {
    commit(SET_VALIDATION_PROCESSING_ID, id);
    const { entities, result } = await submissionApi.validationRule.unignore(
      state.id,
      id
    );
    commit(SET_ENTITIES, { entities, id: result });
    commit(SET_VALIDATION_PROCESSING_ID, null);
    dispatch(
      "snackbar/addInfo",
      { text: "Unignored validation." },
      { root: true }
    );
    return result;
  },
  async process({ state, commit, dispatch }) {
    commit(SET_IS_PROCESSING, true);
    try {
      const { entities, result } = await submissionApi.process(state.id);
      commit(SET_ENTITIES, { entities });
      dispatch(
        "snackbar/addInfo",
        { text: "Processed submission." },
        { root: true }
      );
      return result;
    } finally {
      commit(SET_IS_PROCESSING, false);
    }
  },
  async revalidate({ state, commit, dispatch }) {
    const { entities, result } = await submissionApi.revalidate(state.id);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Revalidated submission." },
      { root: true }
    );
    return result;
  },
  async updateContactDetails(
    { state, commit, dispatch },
    { contactDetails, force }
  ) {
    const { entities, result } = await submissionApi.updateContactDetails(
      state.id,
      contactDetails,
      force
    );
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated contact details." },
      { root: true }
    );
    return result;
  },
  async updateAddress({ state, commit, dispatch }, address) {
    commit(SET_IS_ADDRESS_EDIT_PROCESSING, true);
    const { entities, result } = await submissionApi.updateAddress(
      state.id,
      address
    );
    commit(SET_ENTITIES, { entities });
    commit(SET_IS_ADDRESS_EDIT_PROCESSING, false);
    commit(CLOSE_DIALOG);
    dispatch("snackbar/addInfo", { text: "Updated address." }, { root: true });
    return result;
  },
  async resetAddress({ state, commit, dispatch }) {
    commit(SET_IS_ADDRESS_RESET_PROCESSING, true);
    const { entities, result } = await submissionApi.resetAddress(state.id);
    commit(SET_ENTITIES, { entities });
    commit(SET_IS_ADDRESS_RESET_PROCESSING, false);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch("snackbar/addInfo", { text: "Reset address." }, { root: true });
    return result;
  },
  async updateSubjects({ state, commit, dispatch }, { subjects }) {
    commit(SET_IS_SUBJECT_PROCESSING, true);
    const { entities, result } = await submissionApi.subject.update(
      state.id,
      subjects
    );
    commit(SET_ENTITIES, { entities });
    commit(SET_IS_SUBJECT_PROCESSING, false);
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated submission subjects." },
      { root: true }
    );
    return result;
  },
  async createLead({ state, commit, dispatch }) {
    const { entities, result } = await submissionApi.lead.create(state.id);
    commit(SET_ENTITIES, { entities });
    commit("addLead", result);
    dispatch("snackbar/addInfo", { text: "Created new lead." }, { root: true });
    return result;
  },
  async autoclaimLead({ commit, dispatch }, { leadId, force }) {
    commit("submission/addAutoclaimingLead", leadId);
    const { entities, result } = await leadApi.autoclaim(leadId, force);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch("snackbar/addInfo", { text: "Autoclaimed lead." }, { root: true });
    commit("submission/removeAutoclaimingLead", leadId);
    return result;
  },
  async removeLead({ commit, dispatch }, { leadId }) {
    await leadApi.remove(leadId);
    commit("removeLead", leadId);
    dispatch("snackbar/addInfo", { text: "Removed lead." }, { root: true });
    return true;
  },
  async updateLeadSubjects({ commit, dispatch }, { leadId, subjects }) {
    const { entities, result } = await leadApi.updateSubjects(leadId, subjects);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated lead subjects." },
      { root: true }
    );
    return result;
  },
  async updateSubmissionField({ state, commit, dispatch }, { id, value }) {
    const { entities, result } = await submissionApi.submissionField.update(
      state.id,
      id,
      value
    );
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated submission field." },
      { root: true }
    );
    return result;
  },
  async updateLeadField({ commit, dispatch }, { id, value }) {
    const { entities, result } = await leadFieldApi.update(id, value);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated lead field." },
      { root: true }
    );
    return result;
  },
  async createOffer(
    { commit, dispatch },
    { leadId, filterId, subjectId, price = null }
  ) {
    const { entities, result } = await leadApi.offer.create(
      leadId,
      filterId,
      subjectId,
      price,
      ["lead.offers"]
    );
    commit(SET_ENTITIES, { entities });
    dispatch("snackbar/addInfo", { text: "Created offer." }, { root: true });
    return result;
  },
  async createOfferGroup({ commit, dispatch }, { leadId, group }) {
    const { entities, result } = await leadApi.offer.createGroup(leadId, group);
    commit(SET_ENTITIES, { entities });
    dispatch(
      "snackbar/addInfo",
      { text: "Created offer group." },
      { root: true }
    );
    return result;
  },
  async updateOfferField({ commit, dispatch }, { id, value }) {
    const { entities, result } = await offerFieldApi.update(id, value);
    commit(SET_ENTITIES, { entities });
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Updated offer field." },
      { root: true }
    );
    return result;
  },
  async removeOffer({ commit, dispatch }, { leadId, offerId }) {
    commit(SET_OFFER_PROCESSING, { offerId, isProcessing: true });
    const success = await offerApi.remove(offerId);
    if (success) {
      commit("removeOffer", { leadId, offerId });
      commit(SET_OFFER_PROCESSING, { offerId, isProcessing: false });
      dispatch("snackbar/addInfo", { text: "Removed offer." }, { root: true });
      return true;
    }
    return false;
  },
  async distributeOffer({ commit, dispatch }, { offerId }) {
    const { entities, metas } = await offerApi.distribute(offerId);
    commit(SET_ENTITIES, { entities });

    if (metas.every(meta => meta.success)) {
      // If all distributions where successful, inform the user.
      dispatch(
        "snackbar/addInfo",
        { text: "Distributed offer." },
        { root: true }
      );
    } else {
      // For every failed distribution, inform the user why.
      metas
        .filter(meta => !meta.success)
        .forEach(meta => {
          dispatch("snackbar/addError", { text: meta.error }, { root: true });
        });
    }

    return metas;
  },
  async rejectOffer({ commit, dispatch }, { offerId, reason, comment }) {
    const { entities, result } = await offerApi.reject(
      offerId,
      reason,
      comment
    );
    commit(SET_ENTITIES, { entities });
    dispatch("snackbar/addInfo", { text: "Offer rejected." }, { root: true });
    return result;
  },
  async denyOfferRejection({ commit, dispatch }, { offerId, comment }) {
    const { entities, result } = await offerApi.denyRejection(offerId, comment);
    commit(SET_ENTITIES, { entities });
    dispatch(
      "snackbar/addInfo",
      { text: "Offer rejection denied." },
      { root: true }
    );
    return result;
  },
  async markAsSpam({ state, commit, dispatch }) {
    await submissionApi.markAsSpam(state.id);
    commit(CLOSE_DIALOG);
    dispatch(
      "snackbar/addInfo",
      { text: "Deleted submission." },
      { root: true }
    );
  },

  searchFiltersForLead
};

async function searchFiltersForLead({ commit }, { leadId, search }) {
  const { entities, result } = await leadApi.filter.find(leadId, { search });

  commit(SET_ENTITIES, entities);
  return result;
}

import Vue from "vue";
import { leadField as schema, normalize } from "@/store/schema";
import { createApiUrl } from "@/api/url";

const resourceUrl = createApiUrl("api/v1/lead-fields");

async function update(leadFieldId, value) {
  const url = createApiUrl(`${resourceUrl}/${leadFieldId}`);
  const { data } = await Vue.$http.put(url, { value });
  return normalize(data.data, schema);
}

export default {
  update
};
